<div class="software">Software</div>
<div class="development">Development</div>
<div class="softdev">
  <svg width="100%" height="100%" viewBox="0 0 444 267" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;">
    <g id="Artboard1" transform="matrix(0.975877,0,0,0.961256,0.00146165,-19.2118)">
    <rect x="-0.001" y="19.986" width="455.993" height="258.106" style="fill:none;"/>
      <g transform="matrix(1.02472,0,0,1.04031,-5691.86,-112.917)">
    <g transform="matrix(0.752452,0,-0.193487,-0.338604,5002.43,1076.84)">
    <rect x="1500" y="2400" width="60" height="200" style="fill:rgb(230,230,230);"/>
    </g>
        <g transform="matrix(0.752452,0,-0.193487,0.338604,5002.43,-683.91)">
    <path d="M1560,2400L1500,2400L1500,2600L1560,2716.67L1560,2400Z" style="fill:white;"/>
    </g>
        <g transform="matrix(-0.752452,0,0.193487,0.338604,6547.11,-683.91)">
    <rect x="1500" y="2400" width="60" height="200" style="fill:rgb(230,230,230);"/>
    </g>
        <g transform="matrix(-0.752452,0,0.193487,-0.338604,6547.11,1076.84)">
    <path d="M1560,2400L1500,2400L1500,2600L1560,2716.67L1560,2400Z" style="fill:white;"/>
    </g>
        <g transform="matrix(0.752452,0,-0.274107,0.47969,5287.28,-1022.5)">
    <path d="M1560,2400L1517.18,2400C1506.56,2400 1500,2408.93 1500,2423.39L1500,2552.94L1551.41,2552.94C1556.72,2552.94 1560,2548.48 1560,2541.24L1560,2400Z" style="fill:rgb(250,200,67);"/>
    </g>
        <g transform="matrix(-0.752452,9.21491e-17,0.274107,-0.47969,6262.25,1415.45)">
    <path d="M1560,2400L1516.46,2400C1506.28,2400 1500,2408.56 1500,2422.42L1500,2552.94L1551.41,2552.94C1556.72,2552.94 1560,2548.48 1560,2541.24L1560,2400Z" style="fill:rgb(250,200,67);"/>
    </g>
        <g transform="matrix(8.09927,0,0,8.09929,5553.7,374.573)">
    <text x="0px" y="0px" style="font-family:'Lato-Black', 'Lato', sans-serif;font-weight:900;font-size:12px;fill:rgb(250,200,67);">SOFT</text>
    </g>
        <g transform="matrix(8.09927,0,0,8.09929,5799.84,374.573)">
    <text x="0px" y="0px" style="font-family:'Lato-Black', 'Lato', sans-serif;font-weight:900;font-size:12px;fill:white;">DEV</text>
    </g>
    </g>
    </g>

  </svg>
  <div class="mail">contact@softdev.fr</div>
</div>
